import React from "react"
import { BREAKPOINTS } from "src/utils/exportFunctions"
import topMvImgSp from 'src/images/top_mv_sp.jpg'
import topMvImgTab from 'src/images/top_mv_tab.jpg'
import topMvImgPc from 'src/images/top_mv_pc.jpg'

const StylesIndex = (props) => {
    return (
        <style>{`
        #Index #mv {
            background-image: url(${topMvImgSp});
            height: 157.6vw;
        }

        #Index #mv h1 {
            width: 50%;
        }

        @media (min-width: ${BREAKPOINTS['md']}px) {
            #Index #mv {
                background-image: url(${topMvImgTab});
                height: 110.4vw;
            }

            #Index #mv h1 {
                width: 35%;
            }
        }

        @media (min-width: ${BREAKPOINTS['lg']}px) {
        }

        @media (min-width: ${BREAKPOINTS['xl']}px) {
            #Index #mv {
                background-image: url(${topMvImgPc});
                height: 128.1vw;
            }

            #Index #mv h1 {
                width: 492px;
            }
        }
        `}
        </style>
    );
};

export default StylesIndex;