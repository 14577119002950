import React from "react"
import {BREAKPOINTS} from "src/utils/exportFunctions"

const StylesTopViewMore = (props) => {
    return (
        <style>{`
        .topViewMore{
            padding-bottom: 98px;
        }

        .topViewMore .linkArea{
            width: 222px;
            bottom: 40px;
        }
        .topViewMore .linkArea.left{
            left: 6.25%;
        }
        .topViewMore .linkArea.right{
            right: 6.25%;
        }

        .topViewMore h2{
            padding-bottom: 40px;
        }

        .topViewMore:first-of-type h2{
            padding-top: 40px;
        }

        .topViewMore a .allow{
            width: 56px;
        }

        @media (min-width: ${BREAKPOINTS['md']}px) {
            .topViewMore{
                padding-bottom: 74px;
            }

            .topViewMore .linkArea{
                width: 333px;
                bottom: 40px;
            }
            .topViewMore .linkArea.left{
                left: 6.5%;
            }
            .topViewMore .linkArea.right{
                right: 6.5%;
            }

            .topViewMore h2{
                padding-bottom: 43px;
            }
            .topViewMore:first-of-type h2{
                padding-top: 43px;
            }

            .topViewMore a .allow{
                width: 75px;
            }
        }
        @media (min-width: ${BREAKPOINTS['lg']}px) {
        }
        @media (min-width: ${BREAKPOINTS['xl']}px) {
            .topViewMore{
                padding-bottom: 91px;
            }

            .topViewMore .linkArea{
                width: 410px;
                bottom: 50px;
            }
            .topViewMore .linkArea.left{
                left: 10%;
            }   
            .topViewMore .linkArea.right{
                right: 10%;
            }

            .topViewMore h2{
                padding-bottom: 53px;
            }
            .topViewMore:first-of-type h2{
                padding-top: 53px;
            }

            .topViewMore a .allow{
                width: 92px;
            }
        }
        `}
        </style>
    );
};

export default StylesTopViewMore;