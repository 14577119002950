import * as React from "react"
import { Link } from "gatsby"
import Layout from "src/components/layout"
//import Seo from "src/components/seo"
import mvTitleImg from 'src/images/top_mv_title.svg'
import scrollImg from 'src/images/top_scroll.svg'
import Meta from "src/components/meta"
import TopViewMore from "src/components/topViewMore"
import { PAGE_IDS } from "src/utils/exportFunctions"
import StylesIndex from "src/styles/index"
import StylesTopViewMore from "src/components/styles/topViewMore"
import StylesTitleSvg from "src/components/styles/titleSvg";
import { AnimUp, AnimIn } from "src/components/animation.js"

const IndexPage = () => {
    return (
        <Layout>
            <Meta
                title=""
                desc="ライオン企画株式会社のホームページです。ライオン企画株式会社は教育情報サービスを通して若者の未来想像を応援しています。"
            />
            <StylesIndex />
            <div id="Index">
                <AnimIn>
                    <div id="mv" className="
                        pt-6 bg-cover bg-no-repeat bg-bottom relative h-screen
                        md:pt-10
                        xl:pt-24
                    ">
                        <h1 className="
                            mx-auto w-3/5
                            md:w-5/12
                        ">
                            <AnimUp delay="750">
                                <img src={mvTitleImg} alt="ライオン企画株式会社は教育情報サービス・教育ビジネスの活動を通して、若者の未来創造を応援します。" width="492" height="933"/>
                            </AnimUp>
                        </h1>
                        <Link to="/#contents" className="
                            absolute left-4 top-1/4 w-4
                            md:left-6 md:w-6
                        "><img src={scrollImg} alt="SCROLL" /></Link>
                    </div>
                </AnimIn>
                <StylesTitleSvg />
                <StylesTopViewMore />
                <div id="contents">
                    <TopViewMore pageId={PAGE_IDS['services']} type="left" />
                    <TopViewMore pageId={PAGE_IDS['company']} type="right" />
                    <TopViewMore pageId={PAGE_IDS['access']} type="left" />
                </div>
            </div>
        </Layout>
    )
};

export default IndexPage
