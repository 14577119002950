import React from "react"
import Image from "src/components/image"
import titleArwImg from 'src/images/title_arrow.svg'
import TitleSvg from "src/components/titleSvg";
import { Link } from "gatsby"
import { getJpTitle, getPagesName } from "src/utils/exportFunctions"
import { AnimUp, AnimLeft, AnimRight, AnimIn } from "src/components/animation.js"

const TopViewMore = (props) => {
    const pageName = getPagesName(props.pageId);
    const mainImgName = 'top_' + getPagesName(props.pageId);
    const viewmoreId = 'topViewMore_' + pageName;
    const linkAreaHtml = (
        <div className="bg-links-top p-6 md:p-14 xl:p-20">
            <p className="
                    text-20px tracking-35em leading-none font-thin mb-6
                    md:text-25px md:mb-12
                    xl:text-30px xl:mb-16
            ">{getJpTitle(props.pageId)}</p>
            <Link to={'/' + pageName} className="
                    flex justify-around bg-white border border-black py-7
                    xl:py-9
                ">
                <p className="
                        text-13px tracking-35em leading-none font-thin
                        md:text-15px
                        xl:text-18px
                    ">view more</p>
                <img src={titleArwImg} alt='詳細へ' className="allow" />
            </Link>
        </div>
    );
    return (
        <div id={viewmoreId} className="topViewMore relative">
            <h2>
                <AnimUp><TitleSvg pageId={props.pageId} className="mx-auto" /></AnimUp>
            </h2>
            <AnimIn>
                <Image 
                    isUsePicture={true}
                    ext={"jpg"}
                    filename={mainImgName}
                    alt={pageName}
                    className='w-full'
                    sizes={{
                        'width': {'sp': 641, 'tab': 1536, 'pc': 2560},
                        'height': {'sp': 313, 'tab': 540, 'pc': 665}
                    }}
                />
            </AnimIn>
            <div className={"linkArea " + props.type + " absolute"}>
               {props.type === 'left'? <AnimLeft>{linkAreaHtml}</AnimLeft> : <AnimRight>{linkAreaHtml}</AnimRight>}
            </div>
        </div>
    );
};

export default TopViewMore;